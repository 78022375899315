import React from "react";
import ErrorPage from "../components/ErrorPage";


const ForbiddenPage = () => {
  return (
    <ErrorPage
      error_code={"403: Forbidden"}
      error_desc={"Access Denied."}>
    </ErrorPage>
  );
}

export default ForbiddenPage;

